<template>
<!--  <div>-->
<!--    <b-card no-body>-->
<!--      <b-row class="">-->

<!--        <b-col cols="12"  xl="6">-->
<!--          <div class="d-md-flex flex-row mb-2  text-md-left text-center">-->

<!--            <b-button-->
<!--                @click="$router.back()"-->
<!--                class="shadow-sm font-weight-bold  align-self-baseline float-left"-->
<!--                variant="white">-->
<!--              <feather-icon icon="ChevronLeftIcon" size="20"/>-->
<!--            </b-button>-->

<!--&lt;!&ndash;            <b-avatar :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')"&ndash;&gt;-->
<!--&lt;!&ndash;                      class="mt-2 mr-3 mr-md-0"&ndash;&gt;-->
<!--&lt;!&ndash;                      size="100px"&ndash;&gt;-->
<!--&lt;!&ndash;            />&ndash;&gt;-->
<!--            <b-avatar v-if="profileDetails" :src="getUserImage(profileDetails.id, true)"-->
<!--                      class="mr-n1 mt-1 "-->
<!--                      size="110px"-->
<!--                      style="padding:7px"-->
<!--            />-->
<!--            <div class="m-2 font-weight-bolder profile-text">-->

<!--                <h3 class="text-dark ml-1">{{profileDetails.first_name}} {{profileDetails.last_name}}</h3>-->
<!--              <b-row>-->
<!--                <b-col cols="12">-->
<!--                <b-tabs>-->
<!--                  <b-tab title="Personal Info">-->
<!--                    <div class="alert-body">-->
<!--                      <b-col cols="12 ">-->
<!--                          <span class="mr-1">-->
<!--                              <feather-icon icon="PhoneIcon"/>-->
<!--                              {{profileDetails.phone_number ? profileDetails.phone_number : 'N/A' }}-->
<!--                          </span>-->
<!--                          <span class="mr-1">-->
<!--                            <feather-icon icon="MailIcon"/>-->
<!--                            {{profileDetails.email}}-->
<!--                          </span>-->
<!--                          </b-col>-->
<!--                    </div>-->
<!--                  </b-tab>-->
<!--                  <b-tab title="Professional Info" title-item-class="ml-4 mr-4">-->
<!--                    <div class="alert-body">-->
<!--                      <div v-if="!profileDetails.qalifications" > <h5 class="ml-1 mb-1">No professional information has been saved</h5></div>-->
<!--                      &lt;!&ndash; <b-row class="ml-1 mb-1" v-if="profileDetails.qalifications">-->
<!--                            <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                              <div >-->
<!--                                <small>Qualification</small>-->
<!--                                <div>-->
<!--                                  <b-row class="pl-0 ">-->
<!--                                  <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                                <h5 class="mb-0 font-weight-700">-->
<!--                                  {{ profileDetails.qalifications.qalification ? profileDetails.qalifications.qalification.name : '' }}-->
<!--                                </h5>-->
<!--                                  </b-col>-->
<!--                                  </b-row>-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                            <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                              <div >-->
<!--                                <small>Speciality</small>-->
<!--                                <div>-->
<!--                                  <b-row class="pl-0 ">-->
<!--                                  <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                                <h5 class="mb-0 font-weight-700">-->
<!--                                  {{ profileDetails.qalifications.speciality ? profileDetails.qalifications.speciality.name : '' }}-->
<!--                                </h5>-->
<!--                                  </b-col>-->
<!--                                  </b-row>-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                            <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                              <div >-->
<!--                                <small>IT System</small>-->
<!--                                <b-row class="pl-0 ">-->
<!--                                  <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                                <h5 class="mb-0 font-weight-700">-->
<!--                                  {{ profileDetails.qalifications.it_system ? profileDetails.qalifications.it_system_id : '' }}-->
<!--                                </h5>-->
<!--                                  </b-col>-->
<!--                                  </b-row>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                            <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                              <div >-->
<!--                                <small>Smart Card Number</small>-->
<!--                                <b-row class="pl-0 ">-->
<!--                                  <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                                <h5 class="mb-0 font-weight-700">-->
<!--                                  {{profileDetails.qalifications.smart_card_number}}-->
<!--                                </h5>-->
<!--                                  </b-col>-->
<!--                                  </b-row>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                            <div class="d-flex align-items-center">-->
<!--                              <div >-->
<!--                                <small>Skills</small>-->
<!--                              <div>-->
<!--                                <b-row class="pl-0 ">-->
<!--                                  <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                                    <b-badge class="mr-1" variant="light-info" :key="skill" v-for="skill in profileDetails.qalifications.skills">-->
<!--                                      <span class="text-dark ">{{skill}}</span>-->
<!--                                    </b-badge>-->
<!--                                  </b-col>-->
<!--                                </b-row>-->
<!--                              </div>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                      </b-row> &ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="d-flex flex-wrap justify-content-start" v-if="profileDetails.qalifications">&ndash;&gt;-->
<!--                        <b-row class="ml-1 mb-1" v-if="profileDetails.qalifications">-->
<!--&lt;!&ndash;                      <div class="mx-md-2">&ndash;&gt;-->
<!--                          <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                        <div> -->
<!--                          <small class="font-weight-light">Qualification</small>-->
<!--                          <div>-->
<!--                            <b-row class="pl-0 ">-->
<!--                              <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                                <h5 class="mb-0 font-weight-700">-->
<!--                                  {{profileDetails.qalifications.qalification?profileDetails.qalifications.qalification.name:''}}-->
<!--                                </h5>-->
<!--                              </b-col>-->
<!--                            </b-row>-->
<!--                          </div>-->
<!--                        </div>-->
<!--&lt;!&ndash;                        <div>&ndash;&gt;-->
<!--&lt;!&ndash;                          <h5 class="font-weight-700"> &ndash;&gt;-->
<!--&lt;!&ndash;                            {{profileDetails.qalifications.qalification?profileDetails.qalifications.qalification.name:''}}&ndash;&gt;-->
<!--&lt;!&ndash;                          </h5>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--                      </div>-->
<!--&lt;!&ndash;                      <div class="mr-md-3">&ndash;&gt;-->
<!--                          <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                        <div> -->
<!--                          <small class="font-weight-light">Speciality</small>-->
<!--                          <div>-->
<!--                            <b-row class="pl-0 ">-->
<!--                              <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                                <h5 class="mb-0 font-weight-700">-->
<!--                                  {{profileDetails.qalifications.speciality?profileDetails.qalifications.speciality.name:''}}-->
<!--                                </h5>-->
<!--                              </b-col>-->
<!--                            </b-row>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        </div>-->
<!--&lt;!&ndash;                          <h5 class="font-weight-700"> &ndash;&gt;-->
<!--&lt;!&ndash;                            {{profileDetails.qalifications.speciality?profileDetails.qalifications.speciality.name:''}}&ndash;&gt;-->
<!--&lt;!&ndash;                          </h5>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="mr-md-3">&ndash;&gt;-->
<!--                          <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                        <div> -->
<!--                          <small class="font-weight-light">IT System</small>-->
<!--                          <b-row class="pl-0 ">-->
<!--                            <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                              &lt;!&ndash;                                                  <h5 class="mb-0 font-weight-700">&ndash;&gt;-->
<!--                              &lt;!&ndash;                                                    {{ user.qalifications.it_system ? user.qalifications.it_system.name : 'N/A' }}&ndash;&gt;-->
<!--                              &lt;!&ndash;                                                  </h5>&ndash;&gt;-->
<!--                              <b-badge class="mr-1" variant="light-info" v-for="professionalInfoItSystem in professionalInfoItSystems">-->
<!--                                <span class="text-dark ">{{professionalInfoItSystem.label}}</span>-->
<!--                              </b-badge>-->
<!--                              <h5 v-if="Object.keys(professionalInfoItSystems).length === 0"  class="mb-0 font-weight-700">N/A-->
<!--                              </h5>-->
<!--                            </b-col>-->
<!--                          </b-row>-->
<!--                        </div>-->
<!--                        </div>-->
<!--&lt;!&ndash;                          <h5 class="font-weight-700"> &ndash;&gt;-->
<!--&lt;!&ndash;                            {{profileDetails.qalifications.it_system?profileDetails.qalifications.it_system.name:''}}&ndash;&gt;-->
<!--&lt;!&ndash;                          </h5>&ndash;&gt;-->
<!--&lt;!&ndash;                          <b-badge class="mr-1" variant="light-info" v-for="professionalInfoItSystem in professionalInfoItSystems">&ndash;&gt;-->
<!--&lt;!&ndash;                            <span class="text-dark ">{{professionalInfoItSystem.label}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                          </b-badge>&ndash;&gt;-->
<!--&lt;!&ndash;                          <h5 v-if="Object.keys(professionalInfoItSystems).length === 0"  class="mb-0 font-weight-700">N/A&ndash;&gt;-->
<!--&lt;!&ndash;                          </h5>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="mr-md-3">&ndash;&gt;-->
<!--                          <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                        <div> -->
<!--                          <small class="font-weight-light">Smart Card Number</small>-->
<!--                          <b-row class="pl-0 ">-->
<!--                            <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                              <h5 class="mb-0 font-weight-700">-->
<!--                                {{profileDetails.qalifications.smart_card_number}}-->
<!--                              </h5>-->
<!--                            </b-col>-->
<!--                          </b-row>-->
<!--                        </div>-->
<!--                        </div>-->
<!--&lt;!&ndash;                          <h5 class="font-weight-700"> &ndash;&gt;-->
<!--&lt;!&ndash;                            {{profileDetails.qalifications.smart_card_number}}&ndash;&gt;-->
<!--&lt;!&ndash;                          </h5>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="mr-md-3 ml-2">&ndash;&gt;-->
<!--                          <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                        <div> -->
<!--                          <small class="font-weight-light">Skills</small>-->
<!--                          <div>-->
<!--                            <b-row class="pl-0 ">-->
<!--                              <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                                <b-badge class="mr-1" variant="light-info" v-for="skill in profileDetails.qalifications.skills">-->
<!--                                  <span class="text-dark ">{{skill}}</span>-->
<!--                                </b-badge>-->
<!--                              </b-col>-->

<!--                            </b-row>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        </div>-->
<!--&lt;!&ndash;                          <b-badge class="mr-1" variant="light-info" :key="skill" v-for="skill in profileDetails.qalifications.skills">&ndash;&gt;-->
<!--&lt;!&ndash;                              <span class="text-primary ">{{skill}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                          </b-badge>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--                        </b-row>-->
<!--                    </div>-->

<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                  </b-tab>-->
<!--                </b-tabs>-->
<!--              </b-col>-->
<!--              </b-row>-->
<!--            </div>-->
<!--          </div>-->
<!--        </b-col>-->
<!--        <b-col cols=""  xl="" class="pt-0 pb-2 pt-xl-2 pb-xl-0 ">-->
<!--          <b-row >-->
<!--            <b-col cols="12" md="7" class="d-flex justify-content-center justify-content-xl-end">-->

<!--              <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                <b-avatar-->
<!--                    variant="light-primary"-->
<!--                    rounded-->
<!--                >-->
<!--                  <feather-icon-->
<!--                      icon="ClockIcon"-->
<!--                      size="18"-->
<!--                  />-->
<!--                </b-avatar>-->
<!--                <div class="ml-1">-->
<!--                  <h4 class="mb-0 font-weight-bolder">-->
<!--                    50-->
<!--                  </h4>-->
<!--                  <small>Hours Worked</small>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; <div class="d-flex align-items-center pr-bx">-->
<!--                <b-avatar-->
<!--                    variant="light-primary"-->
<!--                    rounded-->
<!--                >-->
<!--                  <feather-icon-->
<!--                      icon="CreditCardIcon"-->
<!--                      size="18"-->
<!--                  />-->
<!--                </b-avatar>-->
<!--                <div class="ml-1">-->
<!--                  <h4 class="mb-0 font-weight-bolder">-->
<!--                    £2,650.00-->
<!--                  </h4>-->
<!--                  <small>Earned</small>-->
<!--                </div>-->
<!--              </div> &ndash;&gt;-->
<!--            </b-col>-->
<!--            <b-col cols="12" md="5" class=" pr-3 pl-3 pl-md-0  my-2 my-md-0">-->
<!--              <b-button-->
<!--                  class="btn-block "-->
<!--                  variant="primary"-->
<!--              >-->
<!--                <feather-icon-->
<!--                    icon="SendIcon"-->
<!--                    class="mr-50"-->
<!--                />-->
<!--                <span class="align-middle">Send Message</span>-->
<!--              </b-button>-->
<!--            </b-col>-->
<!--          </b-row>-->

<!--        </b-col>-->

<!--      </b-row>-->

<!--    </b-card>-->
<!--  </div>-->

  <div>
    <b-card no-body>
      <b-row>

        <!-- User Avatar -->

        <b-col cols="12"  xl="1">

          <b-row>
            <b-col cols="12" class="d-flex justify-content-start" >
              <b-button
                  @click="$router.back()"
                  class="shadow-sm font-weight-bold  align-self-baseline float-left"
                  variant="white">
                <feather-icon icon="ChevronLeftIcon" size="20"/>
              </b-button>
              <div style="width: 250px; height:100px">
                <b-avatar v-if="profileDetails" :src="getUserImage(profileDetails.id, true)"
                          class="mr-n1 mt-1 badge-minimal"
                          badge-variant="success"
                          variant="light-primary"
                          style="padding:7px"
                          id="profile-image"
                />
              </div>

            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12"  xl="11" >
          <b-row class="ml-2 mt-1">

            <!-- Name & Alert -->

            <b-col cols="12"  xl="5">
              <div class="d-flex align-items-center mt-2 ml-2">

                <div class="d-md-flex flex-row text-center">

                  <h3 class="text-dark ">{{profileDetails.first_name}} {{profileDetails.last_name}}</h3>
                </div>

              </div>
            </b-col>


            <b-col cols="12"  xl="7" class="pt-0 pb-2 pt-xl-2 pb-xl-0 ">
              <b-row >


                <!-- Hours worked / Earned  -->

                <b-col cols="12" md="7" class="d-flex justify-content-center justify-content-xl-end">



                  <!--                        <div class="d-flex align-items-center pr-bx">-->
                  <!--                          <b-avatar-->
                  <!--                              variant="light-primary"-->
                  <!--                              rounded-->
                  <!--                          >-->
                  <!--                            <feather-icon-->
                  <!--                                icon="CreditCardIcon"-->
                  <!--                                size="18"-->
                  <!--                            />-->
                  <!--                          </b-avatar>-->
                  <!--                          <div class="ml-1">-->
                  <!--                            <h4 class="mb-0 font-weight-bolder">-->
                  <!--                              £2,650.00-->
                  <!--                            </h4>-->
                  <!--                            <small>Earned</small>-->
                  <!--                          </div>-->

                  <!--                        </div>-->


                </b-col>

                <!-- Send Message & Download Button -->

                <b-col cols="12" md="5" class=" pr-3 pl-3 pl-md-0  my-2 my-md-0">
                  <b-row>
                    <b-col cols="12" md="12" class=" pr-3 pl-3 pl-md-0  my-md-0 ">
                      <div class="d-flex align-items-center justify-content-end pr-xl-4 pr-2">
                    <b-avatar
                        variant="light-primary"
                        rounded
                    >
                      <feather-icon
                          icon="ClockIcon"
                          size="18"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <h4 class="mb-0 font-weight-bolder">
                        50
                      </h4>
                      <small>Hours Worked</small>
                    </div>
                  </div>
                      <!-- <b-button
                          class="btn-block "
                          variant="primary"
                      >
                        <feather-icon
                            icon="SendIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Send Message</span>
                      </b-button> -->
                    </b-col>
<!--                    <b-col cols="12" md="2" class=" pl-md-0  my-md-0">-->
<!--                      <b-button-->
<!--                          class="btn-icon bg-white shadow-sm font-weight-bold"-->
<!--                          variant="white"-->
<!--                          @click="isEditProfileSidebarActive = true"-->

<!--                      >-->
<!--                        <feather-icon-->
<!--                            v-b-tooltip.hover.top="'Edit'"-->
<!--                            icon="EditIcon"-->
<!--                            size="20"-->
<!--                            class="text-primary"-->
<!--                        />-->
<!--                      </b-button>-->
<!--                    </b-col>-->
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <!-- Tabs -->
<!--          <b-row class="ml-1">-->
<!--            <b-col cols="12">-->
<!--              <b-tabs lazy>-->

<!--                &lt;!&ndash; 01) Personal Info &ndash;&gt;-->

<!--                <b-tab title="Personal Info">-->
<!--                  <div class="alert-body">-->
<!--                    <b-col cols="12  mt-1 mb-1">-->
<!--                                         <span class="mr-1">-->
<!--                              <feather-icon icon="PhoneIcon"/>-->
<!--                              {{profileDetails.phone_number ? profileDetails.phone_number : '-' }}-->
<!--                          </span>-->
<!--                      <span class="mr-1">-->
<!--                            <feather-icon icon="MailIcon"/>-->
<!--                            {{profileDetails.email}}-->
<!--                          </span>-->
<!--                    </b-col>-->
<!--                  </div>-->
<!--                </b-tab>-->

<!--                &lt;!&ndash; 02) Professional Info &ndash;&gt;-->

<!--                <b-tab title="Professional Info">-->
<!--                  <div class="alert-body">-->
<!--                    <b-row class="ml-1 mb-1" v-if="!profileDetails.qalifications">-->
<!--                      &lt;!&ndash;                      <div class="mx-md-2">&ndash;&gt;-->
<!--                      <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                        <div>-->
<!--                          <small class="font-weight-light">Qualification</small>-->
<!--                          <div>-->
<!--                            <b-row class="pl-0 ">-->
<!--                              <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                                <h5 class="mb-0 font-weight-700">-->
<!--                                 - -->
<!--                                </h5>-->
<!--                              </b-col>-->
<!--                            </b-row>-->
<!--                          </div>-->
<!--                        </div>-->

<!--                      </div>-->
<!--                      &lt;!&ndash;                      <div class="mr-md-3">&ndash;&gt;-->
<!--                      <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                        <div>-->
<!--                          <small class="font-weight-light">Speciality</small>-->
<!--                          <div>-->
<!--                            <b-row class="pl-0 ">-->
<!--                              <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                                <h5 class="mb-0 font-weight-700">-->
<!--                                  - -->
<!--                                </h5>-->
<!--                              </b-col>-->
<!--                            </b-row>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->

<!--                      <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                        <div>-->
<!--                          <small class="font-weight-light">IT System</small>-->
<!--                          <b-row class="pl-0 ">-->
<!--                            <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                              &lt;!&ndash;                                                  <h5 class="mb-0 font-weight-700">&ndash;&gt;-->
<!--                              &lt;!&ndash;                                                    {{ user.qalifications.it_system ? user.qalifications.it_system.name : 'N/A' }}&ndash;&gt;-->
<!--                              &lt;!&ndash;                                                  </h5>&ndash;&gt;-->
<!--                              <b-badge class="mr-1" variant="light-info" :key="professionalInfoItSystem" v-for="professionalInfoItSystem in professionalInfoItSystems">-->
<!--                                <span class="text-dark ">{{professionalInfoItSystem.label}}</span>-->
<!--                              </b-badge>-->
<!--                              <h5 v-if="Object.keys(professionalInfoItSystems).length === 0"  class="mb-0 font-weight-700">- -->
<!--                              </h5>-->
<!--                            </b-col>-->
<!--                          </b-row>-->
<!--                        </div>-->
<!--                      </div>-->

<!--                      <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                        <div>-->
<!--                          <small class="font-weight-light">Smart Card Number</small>-->
<!--                          <b-row class="pl-0 ">-->
<!--                            <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                              <h5 class="mb-0 font-weight-700">-->
<!--                                - -->
<!--                              </h5>-->
<!--                            </b-col>-->
<!--                          </b-row>-->
<!--                        </div>-->
<!--                      </div>-->

<!--                      <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                        <div>-->
<!--                          <small class="font-weight-light">Skills</small>-->
<!--                          <div>-->
<!--                            <b-row class="pl-0 ">-->
<!--                              <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                                - -->
<!--                              </b-col>-->

<!--                            </b-row>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->

<!--                    </b-row>-->

<!--                    <b-row class="ml-1 mb-1" v-if="profileDetails.qalifications">-->
<!--                      &lt;!&ndash;                      <div class="mx-md-2">&ndash;&gt;-->
<!--                      <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                        <div>-->
<!--                          <small class="font-weight-light">Qualification</small>-->
<!--                          <div>-->
<!--                            <b-row class="pl-0 ">-->
<!--                              <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                                <h5 class="mb-0 font-weight-700">-->
<!--                                  {{profileDetails.qalifications.qalification?profileDetails.qalifications.qalification.name:''}}-->
<!--                                </h5>-->
<!--                              </b-col>-->
<!--                            </b-row>-->
<!--                          </div>-->
<!--                        </div>-->

<!--                      </div>-->
<!--                      &lt;!&ndash;                      <div class="mr-md-3">&ndash;&gt;-->
<!--                      <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                        <div>-->
<!--                          <small class="font-weight-light">Speciality</small>-->
<!--                          <div>-->
<!--                            <b-row class="pl-0 ">-->
<!--                              <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                                <h5 class="mb-0 font-weight-700">-->
<!--                                  {{profileDetails.qalifications.speciality?profileDetails.qalifications.speciality.name:''}}-->
<!--                                </h5>-->
<!--                              </b-col>-->
<!--                            </b-row>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->

<!--                      <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                        <div>-->
<!--                          <small class="font-weight-light">IT System</small>-->
<!--                          <b-row class="pl-0 ">-->
<!--                            <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                              &lt;!&ndash;                                                  <h5 class="mb-0 font-weight-700">&ndash;&gt;-->
<!--                              &lt;!&ndash;                                                    {{ user.qalifications.it_system ? user.qalifications.it_system.name : 'N/A' }}&ndash;&gt;-->
<!--                              &lt;!&ndash;                                                  </h5>&ndash;&gt;-->
<!--                              <b-badge class="mr-1" variant="light-info" :key="professionalInfoItSystem" v-for="professionalInfoItSystem in professionalInfoItSystems">-->
<!--                                <span class="text-dark ">{{professionalInfoItSystem.label}}</span>-->
<!--                              </b-badge>-->
<!--                              <h5 v-if="Object.keys(professionalInfoItSystems).length === 0"  class="mb-0 font-weight-700">N/A-->
<!--                              </h5>-->
<!--                            </b-col>-->
<!--                          </b-row>-->
<!--                        </div>-->
<!--                      </div>-->

<!--                      <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                        <div>-->
<!--                          <small class="font-weight-light">Smart Card Number</small>-->
<!--                          <b-row class="pl-0 ">-->
<!--                            <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                              <h5 class="mb-0 font-weight-700">-->
<!--                                {{profileDetails.qalifications.smart_card_number}}-->
<!--                              </h5>-->
<!--                            </b-col>-->
<!--                          </b-row>-->
<!--                        </div>-->
<!--                      </div>-->

<!--                      <div class="d-flex align-items-center pr-xl-4 pr-2">-->
<!--                        <div>-->
<!--                          <small class="font-weight-light">Skills</small>-->
<!--                          <div>-->
<!--                            <b-row class="pl-0 ">-->
<!--                              <b-col class="mt-1 font-weight-light" cols="12">-->
<!--                                <b-badge class="mr-1" variant="light-info" :key="skill" v-for="skill in profileDetails.qalifications.skills">-->
<!--                                  <span class="text-dark ">{{skill}}</span>-->
<!--                                </b-badge>-->
<!--                              </b-col>-->

<!--                            </b-row>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->

<!--                    </b-row>-->
<!--                  </div>-->
<!--                </b-tab>-->
<!--              </b-tabs>-->
<!--            </b-col>-->
<!--          </b-row>-->

        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="12" class="">
          <b-tabs lazy class="ml-5 mr-5">
            <b-tab title="Personal Info" >
              <div class="alert-body">
                <b-card style="background-color: #EBF5FB;" class="mb-0">
                  <personalInfo :userData="profileDetails"  :professionalInfoItSystems="itSystems" />
                </b-card>
              </div>
            </b-tab>

            <b-tab title="Employment Info">
              <div class="alert-body">
                <b-card style="background-color: #EBF5FB" class="mb-0">
                  <employmentInfo :userData="profileDetails" />
                </b-card>
              </div>
            </b-tab>

            <b-tab title="Leave">
              <div class="alert-body">
                <b-card style="background-color: #EBF5FB" class="mb-0">
                  <leaves :userData="profileDetails" />
                </b-card>
              </div>
            </b-tab>

            <b-tab title="Working Hours">
              <div class="alert-body">
                <b-card style="background-color: #EBF5FB" class="mb-0">
                  <workingHours />
                </b-card>
              </div>
            </b-tab>

            <b-tab title="Documents & Training">
              <div class="alert-body">
                <b-card style="background-color: #EBF5FB" class="mb-0">
                  <document :userData="profileDetails" />
                </b-card>
              </div>
            </b-tab>

            <b-tab title="Shifts">
              <div class="alert-body">
                <b-card style="background-color: #EBF5FB" class="mb-0">
                  <profileShifts />
                </b-card>
              </div>
            </b-tab>

            <b-tab title="Timesheets">
              <div class="alert-body">
                <b-card style="background-color: #EBF5FB" class="mb-0">
                  <timeSheets />
                </b-card>
              </div>
            </b-tab>

          </b-tabs>
        </b-col>
      </b-row>
    </b-card>

<!--    <b-row class="">-->
<!--      <b-col cols="12">-->
<!--        <pre>{{unAvailabilities}}</pre>-->
<!--        <b-tabs lazy>-->
<!--          <b-tab title="Personal Info">-->
<!--            <div class="alert-body"><personalInfo :userData="profileDetails"  :professionalInfoItSystems="itSystems" /></div>-->
<!--          </b-tab>-->

<!--          <b-tab title="Employment Info">-->
<!--            <div class="alert-body"><employmentInfo :userData="profileDetails" /></div>-->
<!--          </b-tab>-->

<!--          <b-tab title="Leave">-->
<!--            <div class="alert-body"><leaves :userData="profileDetails" /></div>-->
<!--          </b-tab>-->

<!--&lt;!&ndash;          <b-tab title="Working Hours">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="alert-body"><workingHours /></div>&ndash;&gt;-->
<!--&lt;!&ndash;          </b-tab>&ndash;&gt;-->

<!--          <b-tab title="Documents & Training">-->
<!--            <div class="alert-body"><document :userData="profileDetails" /></div>-->
<!--          </b-tab>-->

<!--          <b-tab title="Shifts">-->
<!--            <div class="alert-body"><profileShifts /></div>-->
<!--          </b-tab>-->

<!--&lt;!&ndash;          <b-tab title="Timesheets">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="alert-body"><timeSheets /></div>&ndash;&gt;-->
<!--&lt;!&ndash;          </b-tab>&ndash;&gt;-->

<!--&lt;!&ndash;          <b-tab title="Settings">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="alert-body"><settings /></div>&ndash;&gt;-->
<!--&lt;!&ndash;          </b-tab>&ndash;&gt;-->

<!--                    <b-tab title="Timesheets">-->
<!--                      <div class="alert-body"><timeSheets /></div>-->
<!--                    </b-tab>-->

<!--          &lt;!&ndash;          <b-tab title="Settings">&ndash;&gt;-->
<!--          &lt;!&ndash;            <div class="alert-body"><settings /></div>&ndash;&gt;-->
<!--          &lt;!&ndash;          </b-tab>&ndash;&gt;-->
<!--        </b-tabs>-->
<!--      </b-col>-->
<!--    </b-row>-->

  </div>


</template>

<script>

import {BAvatar, BBadge, BButton, BCard, BCol, BLink, BRow, BTabs, BTab, BOverlay} from 'bootstrap-vue'
import StaffBankAPI from '@/apis/modules/staffBank'
import { mounted } from 'vue-echarts'
import personalInfo from '@/views/staff-bank/staff-profile/includes/personalInfo'
import profileShifts from '@/views/staff-bank/staff-profile/includes/ProfileShifts'
import workingHours from '@/views/staff-bank/staff-profile/includes/workingHours'
import document from '@/views/staff-bank/staff-profile/includes/document'
import leaves from '@/views/staff-bank/staff-profile/includes/leaves'
import timeSheets from '@/views/staff-bank/staff-profile/includes/timeSheets'
import employmentInfo from '@/views/staff-bank/staff-profile/includes/employmentInfo'
// import settings from '@/views/staff-bank/staff-profile/includes/settings'
export default {
  name: 'profile-image-bar',
  components: {
    // settings,
    employmentInfo,
    timeSheets,
    leaves,
    document,
    workingHours,
    profileShifts,
    personalInfo,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BOverlay
  },
  props:{
    userData:{
      type: Object,
      required: true
    },

    itSystems:{
      type: Object,
      required: true
    },
    unAvailabilities:{
      type: Object,
      required: true
    }
  },
  watch:{
    userData () {
      this.profileDetails = this.userData
      this.getItSystems(this.profileDetails.multiple_it_systems)
    },
  },
  data() {
    return {
      profileDetails:'',
      loading:false,
      mediaData: [
        {avatar: 'CalendarIcon', title: 'Sat, May 25, 2020', subtitle: '10:AM to 6:PM'},
      ],
      avatars: [
        {avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Billy Hopkins'},
      ],
      professionalInfoItSystems:{}
    }
  },
  methods: {
    getItSystems(val){
      console.log('val',val)
      this.professionalInfoItSystems = val.map((x) => ({
        // value: x.it_system[0].id,
        label: x.it_system[0].name,

      }))
      console.log(' this.professionalInfoItSystems', this.professionalInfoItSystems)
    }


    // async getProfileDetail(){
    //   try{
    //     this.loading = true
    //     this.profileDetails  = (await StaffBankAPI.getProfileDetails(this.$route.params.id)).data.data[0]
    //     //console.log(this.profileDetails)
    //     this.loading = false
    //   }catch{
    //     this.convertAndNotifyError(error)
    //     this.loading = false
    //   }
    //
    // }
  },
  mounted(){
    // this.getProfileDetail()
    // this.getProfileDetail()
  }
}
</script>

<style scoped>
#profile-image{
  width: 120px;
  height: 110px;
}
</style>
