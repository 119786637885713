<template>
  <b-col
     cols="12"
  >
    <b-card no-body>
      <b-card-header class="border-bottom pb-1">
        <div class="d-flex flex-row">

          <b-avatar
              rounded
              variant="light-primary"
          >
            <feather-icon
                class="text-dark font-weight-700"
                icon="CoffeeIcon"
                size="18"
            />
          </b-avatar>
          <div class="ml-1" style="padding-top:8px">
            <h4 class=" font-weight-700" v-if="userData">
              Leaves <span v-if="userData.leaves.length>0">({{userData.leaves.length}})</span>
            </h4>

          </div>

        </div>
        <div class="ml-auto">
        </div>
      </b-card-header>
      <b-card-body class=" pl-1 px-0 mx-0" v-if="userData">

        <vue-perfect-scrollbar v-if="userData.leaves.length > 0"
                               class="todo-task-list-wrapper list-group scroll-area min-vh-50 "
                               style="max-height: 315px"
        >
          <ul class="list-group list-group-flush">
            <li v-for="lave in userData.leaves" :key="lave.id" class="list-group-item font-black pr-0">
              {{lave.is_day ? `${momentFormat(lave.start, 'YYYY-MM-DD')}` + ` -` + `${momentFormat(lave.end, 'YYYY-MM-DD ')}` : momentFormat(lave.start, 'dddd YYYY-MM-DD') }}

              :{{lave.is_day ? 'Full day' : ' ' +`${momentFormat(lave.start, 'HH:mm')}` + ' ' + `${momentFormat(lave.end, 'HH:mm')}` }}
              <!-- Dropdown -->
              <!--                  <DropDownMenu/>-->

              <div class="float-right" v-if="lave.state !=='requested'">
                <b-badge v-if="lave.state ==='approved'" class="mr-1" style="padding:8px" variant="light-primary" >
                  <span class=""> {{lave.state}} </span>
                </b-badge>
                <b-badge v-if="lave.state ==='rejected'" class="mr-1" style="padding:8px" variant="danger" >
                  <span class=""> {{lave.state}} </span>
                </b-badge>
                <b-badge v-if="lave.state ==='cancelled'" class="mr-1" style="padding:8px" variant="info" >
                  <span class=""> {{lave.state}} </span>
                </b-badge>
              </div>

              <div class="float-right" v-if="lave.state === 'requested'">
                <b-button
                    v-b-tooltip.hover.top="'Accept'"
                    class="btn-icon bg-white text-primary "
                    size="23"

                    variant="outline-white"
                    @click="acceptLeave(lave.id)"
                >
                  <feather-icon icon="CheckSquareIcon" size="18"/>
                </b-button>


                <b-button
                    v-b-tooltip.hover.top="'Reject'"
                    class="btn-icon bg-white text-danger ml-1"
                    size="23"

                    variant="outline-white"
                    @click="rejectLeave(lave.id)"
                >
                  <feather-icon icon="XSquareIcon" size="18" variant="outline-danger"/>
                </b-button>
              </div>

              <br>
              <small class="text-dark">{{ lave.reason === null ? 'N/A' : lave.reason }}</small>
            </li>
          </ul>
        </vue-perfect-scrollbar>
        <div v-else class="d-flex flex-column align-items-center justify-content-center h-100">

          <div class="empty-state py-5">
            <img class="img-fluid" src="@/assets/images/pages/leave-icon.png" alt="">
            <p class="txt-grey mt-1">No Leaves</p>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import DropDownMenu from "@/views/profile/includes/dropdowns/ProfileLeavesDropDownMenu";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BImg,
  BLink,
  BMedia,
  BRow,
  VBTooltip
} from 'bootstrap-vue'
import MomentMixin from "@/mixins/MomentMixin";

export default {
  components: {
    DropDownMenu,
    VBTooltip,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
  },
  mixins: [MomentMixin],

  directives: {
    'b-tooltip': VBTooltip,
  },
  props:{
    userData:{
      type: Object,
      required: true
    }
  },
  // watch:{
  //   userData () {
  //     this.profileDetails = this.userData
  //   },
  // },
  data () {
    return {
      profileDetails:'',
      leaves : [] ,
      documents : [],
      leaveLoading:false
    }
  },
  methods:{}
}
</script>

<style scoped>

</style>