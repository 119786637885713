<template>
  <div>
    <b-overlay
        :show="loading"
        rounded="sm"
    >
    <profile-image-bar :user-data="profileDetails" :itSystems="professionalInfoItSystems"
    />

<!--    <ProfileSecondCardRow-->
<!--        @toggleEditSideBar="isProfileEditLocationSidebarActive = true"-->
<!--        @toggleLeavesSideBar="isAddLeavesSidebarActive = true"-->
<!--        :user-data="profileDetails"-->
<!--    />-->


<!--      <b-row>-->
<!--        <b-col cols="6" md="4">-->
<!--          <ProfileShifts-->
<!--              :user-data="profileDetails"-->
<!--          />-->
<!--        </b-col>-->
<!--        <b-col cols="6" md="4">-->
<!--          <profile-time-sheet-->
<!--              :user-data="profileDetails"-->
<!--          />-->
<!--        </b-col>-->
<!--        <b-col cols="6" md="4">-->
<!--          <Notes-->
<!--          />-->
<!--        </b-col>-->
<!--      </b-row>-->

    <!--    Sidebar-->
    <ProfileEditConnectedLocationsSideBar
        :is-profile-edit-location-sidebar-active.sync="isProfileEditLocationSidebarActive"/>
    <AddLeavesSideBar
        ref="CreateLeaveSideBarSideBar"
        :is-add-leaves-sidebar-active.sync="isAddLeavesSidebarActive"
    />
    </b-overlay>
  </div>
</template>

<script>

import ProfileImageBar from "./includes/ProfileImageBar";
// import ProfileShifts from "./includes/ProfileShifts";
// import Notes from "./includes/Notes.vue";
import ProfileTimeSheet from "./includes/profileTimeSheet";
// import ProfileSecondCardRow from "./includes/ProfileSecondCardRow";

import ProfileEditConnectedLocationsSideBar from "./sidebars/ProfileEditConnectedLocationsSideBar";
import AddLeavesSideBar from "./sidebars/leaves/AddLeavesSideBar";
import StaffBankAPI from "@/apis/modules/staffBank";
import {  BOverlay, BRow, BCol} from 'bootstrap-vue'


export default {
  components: {
    // Notes,
    ProfileTimeSheet,
    AddLeavesSideBar,
    ProfileEditConnectedLocationsSideBar,
    ProfileImageBar,
    // ProfileShifts,
    // ProfileSecondCardRow,
    BOverlay,
    BRow,
    BCol


  },
  data() {
    return {
      loading:false,
      isAddLeavesSidebarActive: false,
      isProfileEditLocationSidebarActive: false,
      profileDetails:{},
      professionalInfoItSystems:{}
    }
  },
  methods:{
    async getProfileDetail(){
      try{
        this.loading = true
        this.profileDetails  = (await StaffBankAPI.getProfileDetails(this.$route.params.id)).data.data[0]
        console.log('this.profileDetails',this.profileDetails)
        this.professionalInfoItSystems = this.profileDetails.multiple_it_systems.map((x) => ({
          value: x.it_system[0].id,
          label: x.it_system[0].name,
        }))
        this.loading = false
      }catch(error){
        this.convertAndNotifyError(error)
        this.loading = false
      }
    }
  },
  mounted() {
    this.getProfileDetail()
  }
}
</script>

