<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4" md="6">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">
              <b-avatar rounded variant="light-primary">
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="UserIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <h4 class="" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Personal Details
                </h4>
              </div>
            </div>
          </b-card-header>

          <b-card-body>
            <b-row class="match-height mt-2">
              <b-col lg="6" md="6">
                <span class="header">First Name</span> <br>
                <h5 class="details" style="margin-top: 10px">
                  {{ userData.first_name }}
                </h5>
              </b-col>

              <b-col lg="6" md="6">
                <span class="ml-5 header">Second Name</span> <br>
                <h5 class="details ml-5" style="margin-top: 10px">
                  {{ userData.last_name }}
                </h5>
              </b-col>

              <b-col lg="6" md="6" class="mt-2">
                <span class="header">DOB</span> <br>
                <h5 class="details" style="margin-top: 10px">
                  {{ userData.dob?ChangeFormateDate(userData.dob):'-'}}
                </h5>
              </b-col>

              <b-col lg="6" md="6" class="mt-2">
                <span class="ml-5 header">NI number</span> <br>
                <h5 class="details ml-5" style="margin-top: 10px">
                  {{ userData.ni_number ? userData.ni_number : '-' }}
                </h5>
              </b-col>

              <b-col lg="6" md="6" class="mt-2">
                <span class="header">Gender</span> <br>
                <h5 class="details" style="margin-top: 10px">
                  {{ userData.gender ? userData.gender : '-' }}
                </h5>
              </b-col>

              <b-col lg="6" md="6" class="mt-2">
                <span class="ml-5 header">Preferred Pronouns</span> <br>
                <h5 class="details ml-5" style="margin-top: 10px">
                  {{ userData.preferred_pronouns ? userData.preferred_pronouns : '-' }}
                </h5>
              </b-col>

              <b-col lg="6" md="6" class="mt-2">
                <span class="header">Alias </span> <br>
                <h5 class="details" style="margin-top: 10px">
                  {{ userData.alias ? userData.alias : '-' }}
                </h5>
              </b-col>
            </b-row>

            <div class="mt-2" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; color: #171822;">
              Bank Details</div>

            <b-row class="match-height mt-2">
              <b-col lg="6" md="6">
                <span class="header">Bank Name</span> <br>
                <h5 class="details" style="margin-top: 10px">
                  {{ userData.bank_details ? userData.bank_details.name : '-' }}
                </h5>
              </b-col>

              <b-col lg="6" md="6">
                <span class="ml-5 header">Account Number</span> <br>
                <h5 class="details ml-5" style="margin-top: 10px">
                  {{ userData.bank_details ? userData.bank_details.account_number : '-' }}
                </h5>
              </b-col>

              <b-col lg="6" md="6" class="mt-2">
                <span class="header">Sort Code</span> <br>
                <h5 class="details" style="margin-top: 10px">
                  {{ userData.bank_details ? userData.bank_details.sort_code : '-' }}
                </h5>
              </b-col>
            </b-row>

          </b-card-body>

        </b-card>
      </b-col>


      <b-col lg="4" md="6">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">


              <b-avatar rounded variant="light-primary">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.66634 2.66602H13.333C14.0663 2.66602 14.6663 3.26602 14.6663 3.99935V11.9993C14.6663 12.7327 14.0663 13.3327 13.333 13.3327H2.66634C1.93301 13.3327 1.33301 12.7327 1.33301 11.9993V3.99935C1.33301 3.26602 1.93301 2.66602 2.66634 2.66602Z" stroke="#171822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14.6663 4L7.99967 8.66667L1.33301 4" stroke="#171822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </b-avatar>

              <div class="ml-1" style="padding-top:8px">
                <h4 class="" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Contact Details
                </h4>
              </div>
            </div>
          </b-card-header>

          <b-card-body>
            <div class="mt-2">
              <span class="mr-1 details">
                <feather-icon class="mr-1" v-b-tooltip.hover.top="'Contact Number'" icon="PhoneIcon"/>
                {{ userData.phone_number ? userData.phone_number : '-' }}
              </span>
            </div>

            <div class="mt-2">
              <span class="mr-1 details">
                <feather-icon class="mr-1" v-b-tooltip.hover.top="'Contact Number'" icon="AtSignIcon"/>
                {{ userData.email ? userData.email : '-' }}
              </span>
            </div>

            <div class="mt-2">
              <span class="mr-1 details">
                <feather-icon class="mr-1" v-b-tooltip.hover.top="'Address'" icon="MapPinIcon"/>
                <span v-if="userData.home_addresses">
                 <span style="margin-left: 1px">{{userData.home_addresses.address?userData.home_addresses.address+', ':''}} </span> <br>
                  <span style="margin-left: 28px">{{userData.home_addresses.address_2?userData.home_addresses.address_2+', ':''}} </span> <br>
                <span style="margin-left: 28px">{{userData.home_addresses.city?userData.home_addresses.city+', ':''}} </span> <br>
                 <span style="margin-left: 28px">{{userData.home_addresses.postal_code?userData.home_addresses.postal_code:''}} </span>
                </span>
                <span v-else>
                  -
                </span>
              </span>
            </div>

            <div class="mt-2">
              <span class="mr-1">
                <feather-icon v-b-tooltip.hover.top="'Emergency Contact Number'" icon="AlertCircleIcon"/>
               <span style="margin-left: 10px" class="details"> {{userData.emergency_contacts? userData.emergency_contacts.contact_name+' - ' + userData.emergency_contacts.contact_number:'-'}} </span>
              </span>
            </div>

          </b-card-body>

        </b-card>
      </b-col>


      <b-col lg="4" md="6">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">


              <b-avatar rounded variant="light-primary">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.333 4.66602H2.66634C1.92996 4.66602 1.33301 5.26297 1.33301 5.99935V12.666C1.33301 13.4024 1.92996 13.9993 2.66634 13.9993H13.333C14.0694 13.9993 14.6663 13.4024 14.6663 12.666V5.99935C14.6663 5.26297 14.0694 4.66602 13.333 4.66602Z" stroke="#171822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.6663 14V3.33333C10.6663 2.97971 10.5259 2.64057 10.2758 2.39052C10.0258 2.14048 9.68663 2 9.33301 2H6.66634C6.31272 2 5.97358 2.14048 5.72353 2.39052C5.47348 2.64057 5.33301 2.97971 5.33301 3.33333V14" stroke="#171822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </b-avatar>

              <div class="ml-1" style="padding-top:8px">
                <h4 class="" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Professional Details
                </h4>
              </div>
            </div>
          </b-card-header>

          <b-card-body>
            <b-row class="match-height mt-2">
              <b-col lg="6" md="6">
                <span class="header">Job Role</span> <br>
                <span class="details" style="margin-top: 10px">{{userData.qalifications && userData.qalifications.job_roles?userData.qalifications.job_roles.name:'-'}}</span>
              </b-col>

              <b-col lg="6" md="6">
                <span class="ml-5 header">Qualification</span> <br>
                <h5 class="details ml-5" style="margin-top: 10px">
                  {{ userData.qalifications && userData.qalifications.qalification ? userData.qalifications.qalification.name : '-' }}
                </h5>
              </b-col>

              <b-col lg="6" md="6" class="mt-2">
                <span class="header">Speciality</span> <br>
                <h5 class="details" style="margin-top: 10px">
                  {{ userData.qalifications && userData.qalifications.speciality ? userData.qalifications.speciality.name : '-' }}
                </h5>
              </b-col>

              <b-col lg="6" md="6" class="mt-2">
                <span class="ml-5 header">IT System</span> <br>
                <b-row class="pl-0 ">
                  <b-col class="mt-1 font-weight-light" cols="12">
                <b-badge class="mr-1 ml-4" style="margin-top: 5px" variant="light-info" v-for="professionalInfoItSystem in professionalInfoItSystems">
                  <span class="text-dark details">{{professionalInfoItSystem.label}}</span>
                </b-badge>
                <h5 v-if="Object.keys(professionalInfoItSystems).length === 0"  class="mb-0 ml-5">-
                </h5>
                  </b-col>
                </b-row>
              </b-col>

              <b-col lg="6" md="6" class="mt-2">
                <span class="header">Smart Card Number</span> <br>
                <h5 class="details" style="margin-top: 10px">
                  {{userData.qalifications && userData.qalifications.smart_card_number?userData.qalifications.smart_card_number:'-'}}
                </h5>
              </b-col>

              <b-col lg="12" class="mt-2">
                <span class="header">Skills</span> <br>
                <div>
                  <b-row v-if="userData.qalifications && userData.qalifications.skills" class="pl-0 ">
                    <b-col class="mt-1 font-weight-light" cols="12">
                      <b-badge class="mr-1" variant="light-info" v-for="skill in userData.qalifications.skills">
                        <span class="text-dark details">{{skill}}</span>
                      </b-badge>
                      <h5 v-if="Object.keys(userData.qalifications.skills).length === 0"  class="mb-0 ">-
                      </h5>
                    </b-col>
                  </b-row>
                  <b-row v-else>
                    <span class="ml-2">-</span>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

        </b-card>
      </b-col>

      <b-col lg="4" md="6">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">


              <b-avatar rounded variant="light-primary">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 13.334H14" stroke="#171822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11 2.33414C11.2652 2.06892 11.6249 1.91992 12 1.91992C12.1857 1.91992 12.3696 1.9565 12.5412 2.02757C12.7128 2.09864 12.8687 2.20281 13 2.33414C13.1313 2.46546 13.2355 2.62136 13.3066 2.79294C13.3776 2.96452 13.4142 3.14842 13.4142 3.33414C13.4142 3.51985 13.3776 3.70375 13.3066 3.87533C13.2355 4.04691 13.1313 4.20281 13 4.33414L4.66667 12.6675L2 13.3341L2.66667 10.6675L11 2.33414Z" stroke="#171822" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <h4 class="" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Notes
                </h4>
              </div>
            </div>
            <div class="ml-auto">
              <feather-icon
                  v-b-tooltip.hover.top="'Add Notes'"
                  class="text-primary cursor-pointer"
                  icon="PlusIcon"
                  size="20"
                  @click="toggleSidebar()"
              />
            </div>
          </b-card-header>

          <b-card-body class=" pl-1 px-0 mx-0">
            <b-row v-if="noUserNotes !== 0">
              <b-col cols="12">
                <b-overlay
                    :show="tableLoading"
                    rounded="sm"
                >
                  <b-table
                      ref="table"
                      :current-page="currentPage"
                      :per-page="pagination.perPage"
                      :fields="fields"
                      :filter="filter"
                      :items="getUserNotes"
                      :filter-included-fields="filterOn"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      hover
                      class="mobile_table_css table_shifts"
                      responsive
                      style="max-height: 400px"
                  >
                    <template #cell(note)="data">
                      <b-row>
                        <b-col cols="1">
                        <svg width="2" height="38" viewBox="0 0 2 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="2" height="38" fill="#00C49A"/>
                        </svg>
                        </b-col>
                        <b-col cols="10">
                        <span class="ml-0" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 12px; line-height: 15px; color: rgba(23, 24, 34, 0.6);">
                          Added on {{data.item.date}} <br>
                         <span style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: #171822; word-break: break-all;"> {{ data.item.note }} </span>
                        </span>
                        </b-col>

                        <b-col cols="1" class="ml-auto">
                          <b-button
                              v-b-tooltip.hover.left="'Delete'"
                              class="btn-icon bg-white mr-0"
                              size="23"
                              variant="outline-white"
                              @click="deleteNote(data.item.id)"
                          >
                            <feather-icon icon="TrashIcon" size="18" variant="outline-danger"/>
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>


                  </b-table>
                </b-overlay>
              </b-col>

            </b-row>
            <div v-else class="d-flex flex-column align-items-center justify-content-center h-10">

              <div class="empty-state py-1">
                <img class="img-fluid" src="@/assets/images/pages/doc-icon.png" alt="" width="100">
                <p class="txt-grey mt-1">No Notes</p>
              </div>

            </div>
          </b-card-body>

        </b-card>
      </b-col>



    </b-row>

    <AddNoteSidebar
        ref="AddNoteSidebar"
        :is-add-note-sidebar-active="isAddNoteSidebarActive"
        @toggleSidebar="toggleSidebar()"
        @dataRefresh="dataRefresh()"
    />


  </div>

</template>

<script>
// import ProfileDownloadDropDownMenu
//   from '@/views/staff-bank/staff-profile/includes/dropdowns/ProfileDownloadDropDownMenu'
// import DropDownMenu from '@/views/profile/includes/dropdowns/ProfileLeavesDropDownMenu'
import AddNoteSidebar from '../sidebars/addNoteSidebar.vue'
// import EditNoteSidebar from '../sidebars/editNoteSidebar.vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol, BDropdown, BDropdownItem, BFormFile, BFormGroup, BFormInput,
  BImg, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
  BLink, BListGroup, BListGroupItem,
  BMedia, BModal, BOverlay,
  BRow, BTab, BTabs,BTable,
  VBTooltip
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import MomentMixin from '@/mixins/MomentMixin'
import UserNoteAPI from '@/apis/modules/notes'
// import staffBank from '@/apis/modules/staffBank'

export default {
  name: 'personalInfo',
  components: {
    AddNoteSidebar,
    flatPickr,
    VBTooltip,
    BTable,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    vSelect,
    BModal,
    BFormFile,
    BOverlay,
    BTabs,
    BTab,
    BInputGroup,
    BFormGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [MomentMixin],

  props:{
    userData:{
      type: Object,
      required: true
    },

    professionalInfoItSystems:{
      type: Object,
      required: true
    }
  },

  data(){
    return {
      isEditProfessionalInfoFormActive:true,
      openProfessionalInfoForm:false,
      addLoader: false,
      dropdowns: {
        qualifications: [],
        specialities: [],
        it_systems: [],
        job_roles: []
      },
      dir: 'ltr',
      selected: null,
      option: [{title: 'Dietology'}, {title: 'Food Processing '}, {title: 'Nutrition'}, {title: 'Biochemistry'}],
      job_role_id:'',
      job_role:'',
      it_systems:[],
      openEditContactForm:false,
      isEditContactInfoFormActive:true,
      professionalInformation:{},
      professionalInfo: {},
      professionalInformationItSystems: {},
      changeImageButton: true,
      uploadImage: [],
      jobRoleOption: [],
      personalInfoLoading: false,
      selectedJobRole: {},
      show: false,
      removeButton: true,

      utcConvertedUnAvalibility: [],
      openForm:false,
      professionalInfoItSystems:{},
      user:'',
      isAddNoteSidebarActive: false,
      isEditNoteSidebarActive: false,
      leaves: [],
      tableLoading:false,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      noteDetails:{},
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'note',
          label:'',
        },

        {
          key:'action',
          label:''
        }
      ],
      items: [],
      noUserNotes:''
    }
  },
  async mounted () {

  },

  methods:{
    ChangeFormateDate(oldDate){
      return oldDate.toString().split("-").reverse().join("-");
    },

    async getUserNotes(){
      try {
        this.tableLoading = true
        const Response = await UserNoteAPI.getNotes(this.$route.params.id, this.$route.params.practice)
        this.noUserNotes = Response.data.data.length
        let dataArray = Response.data.data.map((x) => ({
          id: x.id,
          note: x.note,
          date: this.momentFormat(x.created_at,'DD/MM/YYYY')
        }))

        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage= paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false

        return dataArray

      }catch (error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async deleteNote(id){
      try{
        this.tableLoading = true
        this.$swal({
          title: 'Are you sure you want to delete this note?',
          text: '',
          imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
          showCancelButton: true,
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then(async result=>{
          if(result.value){
            const Response = await UserNoteAPI.deleteNote(id)
            this.showSuccessMessage('Note deleted successfully')
            this.$refs.table.refresh()
          }
        })
        this.$refs.table.refresh()
        this.tableLoading = false
      }catch(error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    toggleSidebar() {
      this.isAddNoteSidebarActive = !this.isAddNoteSidebarActive
    },
    toggleEditSidebar(item) {
      this.noteDetails = item
      this.isEditNoteSidebarActive = !this.isEditNoteSidebarActive
    },
    dataRefresh(){
      try{
        this.$refs.table.refresh()
      }catch(e){
        this.noUserNotes = 1
      }
      this.isAddNoteSidebarActive = false
      this.isEditNoteSidebarActive = false
    },


  },
}

</script>

<style>
.header {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: rgba(23, 24, 34, 0.6);
}

.details{
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #171822;
}

.table_shifts table thead{
 display: none !important;
}

</style>