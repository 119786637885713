<template>

  <div>
    <b-card no-body>

      <b-card-header class="border-bottom pb-1">
        <div class="d-flex flex-row">

          <b-avatar
              rounded
              variant="light-primary"
          >
            <feather-icon
                class="text-dark font-weight-bolder"
                icon="ClockIcon"
                size="18"
            />
          </b-avatar>
          <div class="ml-1" style="padding-top:8px">
            <h4 class=" font-weight-700">
              Timesheets
            </h4>
          </div>

        </div>
      </b-card-header>
      <b-card-body class=" pl-1 px-0 mx-0">
<!--          <b-row  v-if="!noDataTable == 0">-->
          <b-row  v-if="false">
            <b-col cols="12">
              <b-table
                :current-page="currentPage"
                :per-page="pagination.perPage"
                :fields="fields"
                :items="getShifts"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                hover
                ref="table"

                class="mobile_table_css table_shifts"
                style="max-height: 300px"
                responsive
                >
                  <template #cell(subject)="data">
                    <div class="pl-1 ">
                      <h5 class="font-weight-700">{{data.item.subject}}
                        <b-badge v-if="data.item.is_covid"
                            :variant="'light-danger'"
                            class="text-capitalize  mr-2"
                        >
                          COVID-19
                        </b-badge>
                      </h5>
                      <span class="mr-1">
                            <feather-icon icon="CalendarIcon"/>
                            {{data.item.date}} - {{data.item.time}}
                      </span>
                    </div>
                  </template>
                  <template #cell(action)>
                    <b-badge
                            :variant="'light-primary'"
                            class="text-capitalize  float-right"
                    >
                      Active
                    </b-badge>
                  </template>
              </b-table>
            </b-col>

            <!-- <b-col class="d-flex align-items-center pl-1 justify-content-center justify-content-sm-start" cols="12" sm="6">
            <span class="text-muted">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
            </b-col> -->

            <!-- <b-col class="d-flex align-items-center pr-4 justify-content-center justify-content-sm-end" cols="12" sm="6">
              <b-pagination
                v-model="currentPage"
                :per-page="pagination.perPage"
                :total-rows="pagination.totalRows"
                class="mb-0 mt-1 mt-sm-0 "
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item">

              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>

            </b-pagination>
            </b-col> -->

          </b-row>
          <div class="d-flex flex-column align-items-center justify-content-center h-100" v-else>
              <!-- {{userData.shifts}} -->
              <div class="empty-state py-5">
<!--                <img class="img-fluid" src="@/assets/images/pages/shift-icon.png" alt="">-->
                <svg width="100" height="100" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M80 0C124.183 0 160 35.817 160 80C160 124.183 124.183 160 80 160C35.817 160 0 124.183 0 80C0 35.817 35.817 0 80 0Z" fill="#F0F8FC"/>
                  <path d="M66.1895 87.4937H92.1618M66.1895 100.484H83.5058" stroke="#1B9AAA" stroke-width="3" stroke-linecap="round"/>
                  <path d="M81.3234 40.7456H54.3247C50.8315 40.7456 48 43.5763 48 47.0666V116.679C48 120.169 50.8315 123 54.3247 123H102.28C105.778 123 108.609 120.169 108.609 116.679V68.0303" stroke="#171822" stroke-width="3" stroke-linecap="round"/>
                  <path d="M111.522 45.8106C111.888 46.9881 112.084 48.2401 112.084 49.5382C112.084 56.4629 106.471 62.0764 99.5463 62.0764C92.622 62.0764 87.0081 56.4629 87.0081 49.5382C87.0081 42.6135 92.622 37 99.5463 37C101.268 37 102.91 37.3472 104.403 37.9755" stroke="#171822" stroke-width="2.5" stroke-linecap="round"/>
                  <path d="M109.825 42.3568C109.157 41.4007 108.357 40.5423 107.453 39.8071" stroke="#171822" stroke-width="2.5" stroke-linecap="round"/>
                  <path d="M98.7554 43.0996V50.1572C98.7554 50.2105 98.7983 50.2536 98.8525 50.2536H104.855" stroke="#1B9AAA" stroke-width="2.5" stroke-linecap="round"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M137.872 55.3193C140.222 55.3193 142.127 57.2249 142.127 59.5747C142.127 61.9244 140.222 63.83 137.872 63.83C135.522 63.83 133.616 61.9244 133.616 59.5747C133.616 57.2249 135.522 55.3193 137.872 55.3193Z" fill="#DCEEF8"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M149.788 41.7021C153.078 41.7021 155.745 44.3694 155.745 47.6596C155.745 50.9498 153.078 53.617 149.788 53.617C146.498 53.617 143.831 50.9498 143.831 47.6596C143.831 44.3694 146.498 41.7021 149.788 41.7021Z" fill="#DCEEF8"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M130.212 40C131.153 40 131.914 40.7617 131.914 41.7021C131.914 42.6426 131.153 43.4043 130.212 43.4043C129.272 43.4043 128.51 42.6426 128.51 41.7021C128.51 40.7617 129.272 40 130.212 40Z" fill="#DCEEF8"/>
                </svg>

                <p class="txt-grey mt-1">No Time Sheets</p>
              </div>

          </div>

      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {BAvatar, BBadge, BCard, BCardBody, BCardHeader, BCol, BImg, BMedia, BRow, BPagination,
    BOverlay,
    BTable} from 'bootstrap-vue'
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ProfileShiftsDropDownMenu from "@/views/profile/includes/dropdowns/ProfileShiftsDropDownMenu";
import StaffBankAPI from "@/apis/modules/staffBank";
import MomentMixin from '@/mixins/MomentMixin'

export default {
  components: {
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BBadge,
    BPagination,
    BOverlay,
    BTable,
    VuePerfectScrollbar,
    ProfileShiftsDropDownMenu
  },
  props:{
    userData:{
      type: Object,
      required: true
    }
  },
  mixins: [MomentMixin],
  data(){
    return {
      fields: [
        {
          key: 'subject',
          label:'',
        },

        {
          key:'action',
          label:''
        }
      ],
      items: [],
      leaves: [],
      tableLoading: false,
      noDataTable:0,
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      sortBy: '',
      sortDesc: false,
    }
  },
   methods:{
    async getShifts(){
      try {
        this.tableLoading = true
        const Response = await StaffBankAPI.getStaffShifts(this.$route.params.id, this.currentPage, 10)
        this.noDataTable = Response.data.data.length
        let dataArray = Response.data.data.map((x) => ({
          id: x.id,
          date: this.momentFormat(x.start,'dddd YYYY-MM-DD'),
          subject:x.service ===  null? '':x.service.name,
          //rate: x.rate,
          time: `${this.momentFormat(x.start,'HH:mm')}` + ' ' + `${this.momentFormat(x.end,'HH:mm')}`,
          is_covid:x.is_covid_shift
        }))
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage= paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false

        return dataArray

      }catch (error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },

  },
  mounted(){
    this.getShifts()
  }
}
</script>
<style  >
.table_shifts table thead{
  display: none !important;
}
</style>
