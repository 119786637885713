import api from '../axios';
const resource = '/api/pcn';

export default {
  getNotes: (id, practice_id) =>
    api.get(`${resource}/staff-bank/notes/${id}/practice/${practice_id}`),
  editNotes: (id, payload) =>
    api.put(`${resource}/staff-bank/notes/${id}`, payload),
  addNotes: (payload) => api.post(`${resource}/staff-bank/notes`, payload),
  deleteNote: (id) => api.delete(`${resource}/staff-bank/notes/${id}`),
};
