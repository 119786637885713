<template>
  <div>
    <b-row class="match-height">
      <b-col lg="6" md="6">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">
              <b-avatar rounded variant="light-primary">
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="CalendarIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <h4 class=" font-weight-700">
                  Working Hours
                </h4>
              </div>
            </div>
          </b-card-header>

          <b-card-body>

            <div v-for="(day,index) in workingHours">
              <b-row class="mt-2" style="margin-left: 5px">
                <b-col cols="4">
                <svg width="2" height="18" viewBox="0 0 2 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="2" height="18" fill="#00C49A"/>
                </svg>
                <span class="ml-1" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; color: #171822;">
                  {{day.day}}
                </span>
                </b-col>
                <b-col cols="8">
                <span v-for="(time,index) in day.times" class="mr-2" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: #171822;">
                  <feather-icon class="mr-1"icon="ClockIcon"/>
                   {{time.start + ' - ' + time.end}} {{','}}</span>
                </b-col>
              </b-row>
            </div>

            <div style="height: 38px; background-color: #EBF5FB; margin-top: 30px">
              <span class="m-1">Total hours/week: {{total}}</span>
            </div>
          </b-card-body>

        </b-card>
      </b-col>


      <b-col lg="6" md="6">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">
              <b-avatar rounded variant="light-primary">
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="SlashIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <span style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Unavailability
                </span>
              </div>
            </div>
          </b-card-header>

          <b-card-body>
            <div v-for="(day,index) in unavailabilities">
              <b-row class="mt-2" style="margin-left: 5px">
                <b-col cols="4">
                <svg width="2" height="18" viewBox="0 0 2 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="2" height="18" fill="#00C49A"/>
                </svg>
                <span class="ml-1" style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 14px; line-height: 18px; color: #171822;">
                  {{day.day}}
                </span>
                </b-col>
                <b-col cols="8">
                <span v-for="(time,index) in day.times" class="mr-2" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: #171822;">
                  <feather-icon class="mr-1"icon="ClockIcon"/>
                   {{time.start + ' - ' + time.end}} {{','}}</span>
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar, BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol, BDropdown, BDropdownItem, BFormFile, BFormGroup, BFormInput,
  BImg, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
  BLink, BListGroup, BListGroupItem,
  BMedia, BModal, BOverlay,
  BRow, BTab,
  BTable, BTabs,
  VBTooltip
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import MomentMixin from '@/mixins/MomentMixin'
import {heightTransition} from '@core/mixins/ui/transition'
import settings from '@/apis/modules/settings'
import {days} from '@/constants/config'
import flatPickr from 'vue-flatpickr-component'
import DateDiff from 'date-diff'
import StaffBankAPI from '@/apis/modules/staffBank'

export default {
  name: 'workingHours',
  components: {
    flatPickr,
    VBTooltip,
    BTable,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    vSelect,
    BModal,
    BFormFile,
    BOverlay,
    BTabs,
    BTab,
    BInputGroup,
    BFormGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [MomentMixin],

  data(){
    return{
      unavailabilities: [],
      addLoader: false,
      days: null,
      nextTodoId: 1,
      value: '',
      utcTime: [],
      workingHours: [],
      total:0,
    }
  },

  mounted () {
   this.getUserDetails()
  },

  methods:{
    openForm(val){
      this.openEditUnavailabilityForm = val
    },

    openWorkingHoursForm(val){
      this.openEditWorkingHoursForm = val
    },

    async getUserDetails () {
      try {
        this.personalInfoLoading = true
        const Response = await StaffBankAPI.getUnavailabilityAndWorkingHoursDetails(this.$route.params.id)
        this.user = Response.data.data
        this.unavailabilities = this.user.un_availabilities.map((x) => ({
          day: x.day,
          day_number: x.day_number,
          times: x.times.map((m) => ({
            start: this.momentFormat(m.start, 'HH:mm'),
            end: this.momentFormat(m.end, 'HH:mm')
          }))
        }))

        this.workingHours = this.user.working_hours.map((x) => ({
          day: x.day,
          day_number: x.day_number,
          times: x.times.map((m) => ({
            start: this.momentFormat(m.start, 'HH:mm'),
            end: this.momentFormat(m.end, 'HH:mm'),
            difference: `${new DateDiff(new Date(m.end), new Date(m.start)).hours()}`,
          }))
        }))
        this.calculateTotal(this.workingHours)
      } catch (error) {
        this.convertAndNotifyError(error)
        this.personalInfoLoading = false
      }
    },

    calculateTotal(val){
      this.total = 0
      for (let i = 0; i < val.length; i++) {
        for (let a = 0; a < val[a].times.length; a++) {
          this.total = Number(this.total) + Number(val[i].times[a].difference)
        }
      }
      return this.total
    },

  }
}
</script>

<style scoped>

</style>