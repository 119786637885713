<template>
  <div>
    <b-row class="match-height">
      <b-col lg="5" md="6">
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">
              <b-avatar rounded variant="light-primary">
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="MapPinIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <span style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
                  Locations
                </span>
              </div>
            </div>
          </b-card-header>

          <b-card-body class=" pl-1 px-0 mx-0">
            <vue-perfect-scrollbar
                class="todo-task-list-wrapper list-group scroll-area min-vh-50"
                style="max-height: 315px"
            >
              <ul class="list-group list-group-flush">

                <li v-for="index in [1,2]" class="list-group-item font-black">
                  Tottenham Hale Medical
                  Practice
                  <br>
                  <small class="text-dark">47 Crown Street, London, W1H 8BR</small>
                </li>

              </ul>
            </vue-perfect-scrollbar>

          </b-card-body>
        </b-card>
      </b-col>

<!--      <b-col lg="7" md="6">-->
<!--        <b-card no-body>-->
<!--          <b-card-header class="border-bottom pb-1">-->
<!--            <div class="d-flex flex-row">-->
<!--              <b-avatar rounded variant="light-primary">-->
<!--                <feather-icon-->
<!--                    class="text-dark font-weight-700"-->
<!--                    icon="FileTextIcon"-->
<!--                    size="18"-->
<!--                />-->
<!--              </b-avatar>-->
<!--              <div class="ml-1" style="padding-top:8px">-->
<!--                <span style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">-->
<!--                  Employment Info-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </b-card-header>-->

<!--          <b-card-body>-->
<!--            <b-row class="match-height mt-2">-->

<!--              <b-col lg="6" md="6">-->
<!--                <b-row>-->
<!--                  <b-col lg="6" md="6">-->
<!--                    <span style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: rgba(23, 24, 34, 0.6);">-->
<!--                      Date Hired-->
<!--                    </span>-->
<!--                    <h5 class="mb-0" style="margin-top: 10px; font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: #171822;">-->
<!--                      {{ userData.practice_user_employment_information ? ChangeFormateDate(userData.practice_user_employment_information.date_hired) : '-' }}-->
<!--                    </h5>-->
<!--                  </b-col>-->

<!--                  <b-col lg="6" md="6">-->
<!--                    <span style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: rgba(23, 24, 34, 0.6);">-->
<!--                      Employment Type-->
<!--                    </span>-->
<!--                    <h5 class="mb-0" style="margin-top: 10px; font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: #171822;">-->
<!--                      {{ userData.practice_user_employment_information ? userData.practice_user_employment_information.employment_type : '-' }}-->
<!--                    </h5>-->
<!--                  </b-col>-->
<!--                </b-row>-->

<!--                <b-row class="mt-2">-->
<!--                  <b-col lg="6" md="6">-->
<!--                    <span style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: rgba(23, 24, 34, 0.6);">-->
<!--                      Salary-->
<!--                    </span>-->
<!--                    <h5 class="mb-0" style="margin-top: 10px; font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: #171822;">-->
<!--                      {{ userData.practice_user_employment_information ? '£ '+ new Intl.NumberFormat().format(userData.practice_user_employment_information.salary)   : '-' }}-->
<!--                    </h5>-->
<!--                  </b-col>-->

<!--                  <b-col lg="6" md="6">-->
<!--                    <span style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: rgba(23, 24, 34, 0.6);">-->
<!--                      DBS Status-->
<!--                    </span>-->
<!--                    <h5 class="mb-0" style="margin-top: 10px; font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: #171822;">-->
<!--                      {{ userData.practice_user_employment_information ? userData.practice_user_employment_information.dbs_status : '-' }}-->
<!--                    </h5>-->
<!--                  </b-col>-->
<!--                </b-row>-->

<!--                <b-row class="mt-2">-->
<!--                  <b-col lg="12" md="6">-->
<!--                    <span style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: rgba(23, 24, 34, 0.6);">-->
<!--                      DBS Renewal Date-->
<!--                    </span>-->
<!--                    <h5 class="" style="margin-top: 10px; font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: #171822;">-->
<!--                      {{ userData.practice_user_employment_information ? ChangeFormateDate(userData.practice_user_employment_information.dbs_renewal_date) : '-' }}-->
<!--                    </h5>-->
<!--                  </b-col>-->


<!--                </b-row>-->
<!--              </b-col>-->

<!--              <b-col lg="6" md="6">-->
<!--                <span class="" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: rgba(23, 24, 34, 0.6);">-->
<!--                  Employment Contract-->
<!--                </span>-->
<!--&lt;!&ndash;                <h5 class="mb-0" style="margin-top: 20px; font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px">&ndash;&gt;-->
<!--&lt;!&ndash;                  <feather-icon style="color: #1B9AAA; width: 20px; height: 20px;" icon="FilePlusIcon"/>&ndash;&gt;-->
<!--&lt;!&ndash;                  <span class="ml-1" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; color: #1B9AAA;">&ndash;&gt;-->
<!--&lt;!&ndash;                    Upload Employment Contract&ndash;&gt;-->
<!--&lt;!&ndash;                  </span>&ndash;&gt;-->
<!--&lt;!&ndash;                </h5>&ndash;&gt;-->

<!--                <span class="mt-3" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px; color: rgba(23, 24, 34, 0.6);">-->
<!--                 Job Specification-->
<!--                </span>-->
<!--&lt;!&ndash;                <h5 class="mb-0" style="margin-top: 20px; font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 18px">&ndash;&gt;-->
<!--&lt;!&ndash;                  <feather-icon style="color: #1B9AAA; width: 20px; height: 20px;" icon="FilePlusIcon"/>&ndash;&gt;-->
<!--&lt;!&ndash;                  <span class="ml-1" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; color: #1B9AAA;">&ndash;&gt;-->
<!--&lt;!&ndash;                    Upload Job Specification&ndash;&gt;-->
<!--&lt;!&ndash;                  </span>&ndash;&gt;-->
<!--&lt;!&ndash;                </h5>&ndash;&gt;-->
<!--              </b-col>-->


<!--            </b-row>-->

<!--          </b-card-body>-->
<!--        </b-card>-->
<!--      </b-col>-->
    </b-row>
  </div>

</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol, BDropdown, BDropdownItem, BFormFile, BFormGroup, BFormInput,
  BImg, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
  BLink, BListGroup, BListGroupItem,
  BMedia, BModal, BOverlay,
  BRow, BTab, BTabs,BTable,
  VBTooltip
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import MomentMixin from '@/mixins/MomentMixin'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'


export default {
  name: 'employmentInfo',
  components: {
    VBTooltip,
    BTable,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    vSelect,
    BModal,
    BFormFile,
    BOverlay,
    BTabs,
    BTab,
    BInputGroup,
    BFormGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [MomentMixin],

  props: {
    userData: {
      type: Object,
      required: true
    }
  },

  data(){
   return{
     isActiveEditEmployInfo: true,
     openEditEmployInfo: false,
     connectedLocations: [],
     user:'',
     loading: false,
     isHaveProfileImage: false,
     userImage: null,
     changeImageButton: true,
     uploadImage: [],
     userData: {},

     form: {
       date_hired:'',
       employment_type:'',
       salary:'',
       dbs_status:'',
       dbs_renewal_date:''
     },
     EmploymentInfo: {}
   }
  },

  mounted() {

  },

  methods: {
    ChangeFormateDate(oldDate){
      return oldDate.toString().split("-").reverse().join("-");
    },

  }
}
</script>

<style scoped>

</style>