import api from '../axios';
const resource = '/api/pcn/staff-bank';

export default {
  getUserUnAssignTrainings: (id) =>
    api.get(`${resource}/training-list/${id}`),
  getAllTrainings: () => api.get(`${resource}settings/training-list`),
  updateTraining: (id, payload) =>
    api.put(`${resource}/training-list/user-training/${id}`, payload),
  addNewTraining: (payload) =>
    api.post(`${resource}/training-list/user-training`, payload),
  getAllUserTraining: (id) =>
    api.get(`${resource}/training-list/user-training/${id}`),

  getCertificate: (payload) =>
    api.post(`${resource}/training-certificates`, payload),
  getOneCertificate: (id) =>
    api.get(`${resource}/training-certificates/${id}`),
  deleteCertificate: (id) =>
    api.delete(`${resource}/training-certificates/${id}`),
  downloadCertificate: (id) =>
    api.get(`${resource}/training-certificates/${id}/download`),
};
