<template>
  <b-col cols="12">
    <b-card no-body>
      <b-card-header class="border-bottom pb-1">
        <div class="d-flex flex-row">

          <b-avatar
              rounded
              variant="light-primary"
          >
            <feather-icon
                class="text-dark font-weight-700"
                icon="ClockIcon"
                size="18"
            />
          </b-avatar>
          <div class="ml-1" style="padding-top:8px">
            <h4 class=" font-weight-700">
             <span>Timesheets </span>
              <span>{{pendingApprovelCount}}</span>
            </h4>

          </div>

        </div>
        <div class="ml-auto">
        </div>
      </b-card-header>

      <b-card-body class=" pl-1 px-0 mx-0">
        <ul class="list-group list-group-flush">
          <li v-for="timesheet in timeSheets" :key="timesheet.id" class="list-group-item font-black pr-0">
            <span style="font-family: Mulish;font-style: normal;font-weight: bold;font-size: 16px;line-height: 20px;color: #171822;">{{ timesheet.date }}</span>
            <div>
              <span v-if="timesheet.shift_start !==timesheet.start || timesheet.shift_end !==timesheet.end"
                    class="pt-3" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 21px; color: #949494;">
                <del>
                   <feather-icon
                       class="text-dark font-weight-700 mr-1"
                       icon="ClockIcon"
                       size="18"
                   />
                  <span>{{ timesheet.time }} </span>
                </del>
                <span class="ml-2">
              <feather-icon
                  class="text-dark font-weight-700"
                  icon="ServerIcon"
                  size="18"
              />
                {{ timesheet.programme }}
              </span>
                 <span class="ml-2" v-if="timesheet.commentary">
              <feather-icon
                  class="text-dark font-weight-700"
                  icon="MessageCircleIcon"
                  size="18"
              />
                {{ timesheet.commentary }}
              </span>

                <span class="float-right mr-2">
                <b-button
                    v-if="timesheet.state ==='pending'"
                    variant="outline-white"
                    class="bg-primary text-white"
                    @click="approveUnsubmitted(timesheet)"
                >
                  Approve
                </b-button>


                <b-button
                    v-if="timesheet.state ==='approved'"
                    variant="white"
                    class="btn-icon bg-white text-primary shadow-sm font-weight-bold"
                    @click="unApprovedTimesheet(timesheet.id)"
                >
                  Unapprove
                </b-button>
              </span>
                <br>
                <span class="alert alert-primary d-flex font-weight-bold" style="padding:5px; width: 160px;">
                      <span class=" text-dark"><feather-icon size="15" class="" icon="Edit2Icon"/>
                    {{ timesheet.start }} - {{ timesheet.end }}  </span>
                    </span>
              </span>

              <span v-else>
              <span style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 21px; color: #949494;">
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="ClockIcon"
                    size="18"
                />
                {{ timesheet.time }}
              </span>
                <span class="ml-2" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 21px; color: #949494;">
              <feather-icon
                  class="text-dark font-weight-700"
                  icon="ServerIcon"
                  size="18"
              />
                {{ timesheet.programme }}
              </span>
                 <span class="ml-2" v-if="timesheet.commentary" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 21px; color: #949494;">
              <feather-icon
                  class="text-dark font-weight-700"
                  icon="MessageCircleIcon"
                  size="18"
              />
                {{ timesheet.commentary }}
              </span>

                <span class="float-right mr-2">
                <b-button
                    v-if="timesheet.state ==='pending'"
                    variant="outline-white"
                    class="bg-primary text-white"
                    @click="approveUnsubmitted(timesheet)"
                >
                  Approve
                </b-button>


                <b-button
                    v-if="timesheet.state ==='approved'"
                    variant="white"
                    class="btn-icon bg-white text-primary shadow-sm font-weight-bold"
                    @click="unApprovedTimesheet(timesheet.id)"
                >
                  Unapprove
                </b-button>
              </span>
              </span>
            </div>
          </li>
        </ul>

      </b-card-body>
    </b-card>

  </b-col>

</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol, BDropdown, BDropdownItem, BFormFile, BFormGroup, BFormInput,
  BImg, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
  BLink, BListGroup, BListGroupItem,
  BMedia, BModal, BOverlay,
  BRow, BTab, BTabs, BTable,
  VBTooltip
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import MomentMixin from '@/mixins/MomentMixin'
import TimesheetAPI from '@/apis/modules/timesheet'
import DateDiff from 'date-diff'

export default {
  name: 'timeSheets',
  components: {
    VBTooltip,
    BTable,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    vSelect,
    BModal,
    BFormFile,
    BOverlay,
    BTabs,
    BTab,
    BInputGroup,
    BFormGroup
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  mixins: [MomentMixin],

  data () {
    return {
      timeSheets: [],
      pendingApprovelCount: ''
    }
  },

  mounted () {
    this.getTimeSheets()
  },

  methods: {
    async getTimeSheets () {
      const Response = await TimesheetAPI.getTimesheetsOfUser(this.$route.params.id)
      this.timeSheets = Response.data.all.data.map((x) => ({
        id: x.id,
        start: `${this.momentFormat(x.start, 'HH:mm')}`,
        end: `${this.momentFormat(x.end, 'HH:mm')}`,
        shift: x.shift,
        user_id: x.shift.assignee_id,
        date: `${this.momentFormat(x.start, 'DD/MM/YYYY')}`,
        date_2: `${this.momentFormat(x.start, 'DD/MM/YYYY')}`,
        time: `${this.momentFormat(x.shift.start, 'HH:mm')}` + '-' + `${this.momentFormat(x.shift.end, 'HH:mm')}`,
        hours: `${new DateDiff(new Date(x.end), new Date(x.start)).hours()}`,
        programme: x.shift.pcn_programme.name,
        break: x.shift.break_minutes ? x.shift.break_minutes : 'N/A',
        commentary: x.commentary ? x.commentary : '',
        state: x.state,
        shift_start: `${this.momentFormat(x.shift.start, 'HH:mm')}`,
        shift_end: `${this.momentFormat(x.shift.end, 'HH:mm')}`
      }))
      this.pendingApprovelCount = Response.data.timeSheet_count
    },

    async approveUnsubmitted(item){
      try{
        let payload = {}
        const ids = []
        ids.push(item.id)
        payload.time_sheet_shift_ids = ids
        await TimesheetAPI.timesheetApprove(payload)
        this.showSuccessMessage(`Timesheet from ${item.date_2} was moved to Approved!`)
        await this.getTimeSheets()
        this.getUnapprovedTimesheetsOfUserMethodTwo(item.user_id)
      }catch(error){
        this.convertAndNotifyError(error)
      }
    },

    async unApprovedTimesheet(id){
      try{
        let payload = {}
        const ids = []
        ids.push(id)
        payload.time_sheet_shift_ids = ids
        await TimesheetAPI.submitUnApproved(payload)
        this.showSuccessMessage('Timesheet was Unapproved!')
        await this.getTimeSheets()

      }catch(error){
        this.convertAndNotifyError(error)
      }
    },
  }
}
</script>

<style scoped>

</style>