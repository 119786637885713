<!--<template>-->

<!--  <div>-->
<!--    <b-card no-body>-->

<!--      <b-card-header class="border-bottom pb-1">-->
<!--        <div class="d-flex flex-row">-->

<!--          <b-avatar-->
<!--              rounded-->
<!--              variant="light-primary"-->
<!--          >-->
<!--            <feather-icon-->
<!--                class="text-dark font-weight-bolder"-->
<!--                icon="CalendarIcon"-->
<!--                size="18"-->
<!--            />-->
<!--          </b-avatar>-->
<!--          <div class="ml-1" style="padding-top:8px">-->
<!--            <h4 class=" font-weight-700">-->
<!--              Shifts-->
<!--            </h4>-->
<!--          </div>-->

<!--        </div>-->
<!--      </b-card-header>-->
<!--      <b-card-body class=" pl-1 px-0 mx-0">-->
<!--          <b-row  v-if="!noDataTable == 0">-->
<!--            <b-col cols="12">-->
<!--              <b-table-->
<!--                :current-page="currentPage"-->
<!--                :per-page="pagination.perPage"-->
<!--                :fields="fields"-->
<!--                :items="getShifts"-->
<!--                :sort-by.sync="sortBy"-->
<!--                :sort-desc.sync="sortDesc"-->
<!--                hover-->
<!--                ref="table"-->

<!--                class="mobile_table_css table_shifts"-->
<!--                style="max-height: 300px"-->
<!--                responsive-->
<!--                >-->
<!--                  <template #cell(subject)="data">-->
<!--                    <div class="pl-1 ">-->
<!--                      <h5 class="font-weight-700">{{data.item.subject}}-->
<!--                        <b-badge v-if="data.item.is_covid"-->
<!--                            :variant="'light-danger'"-->
<!--                            class="text-capitalize  mr-2"-->
<!--                        >-->
<!--                          COVID-19-->
<!--                        </b-badge>-->
<!--                      </h5>-->
<!--                      <span class="mr-1">-->
<!--                            <feather-icon icon="CalendarIcon"/>-->
<!--                            {{data.item.date}} - {{data.item.time}}-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                  <template #cell(action)>-->
<!--                    <b-badge-->
<!--                            :variant="'light-primary'"-->
<!--                            class="text-capitalize  float-right"-->
<!--                    >-->
<!--                      Active-->
<!--                    </b-badge>-->
<!--                  </template>-->
<!--              </b-table>-->
<!--            </b-col> -->
<!--             -->
<!--            &lt;!&ndash; <b-col class="d-flex align-items-center pl-1 justify-content-center justify-content-sm-start" cols="12" sm="6">-->
<!--            <span class="text-muted">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>-->
<!--            </b-col> &ndash;&gt;-->
<!--            -->
<!--            &lt;!&ndash; <b-col class="d-flex align-items-center pr-4 justify-content-center justify-content-sm-end" cols="12" sm="6">-->
<!--              <b-pagination-->
<!--                v-model="currentPage"-->
<!--                :per-page="pagination.perPage"-->
<!--                :total-rows="pagination.totalRows"-->
<!--                class="mb-0 mt-1 mt-sm-0 "-->
<!--                first-number-->
<!--                last-number-->
<!--                next-class="next-item"-->
<!--                prev-class="prev-item">-->

<!--              <template #prev-text>-->
<!--                <feather-icon icon="ChevronLeftIcon" size="18"/>-->
<!--              </template>-->

<!--              <template #next-text>-->
<!--                <feather-icon icon="ChevronRightIcon" size="18"/>-->
<!--              </template>-->

<!--            </b-pagination>-->
<!--            </b-col> &ndash;&gt;-->
<!--             -->
<!--          </b-row>-->
<!--          <div class="d-flex flex-column align-items-center justify-content-center h-100" v-else>-->
<!--              &lt;!&ndash; {{userData.shifts}} &ndash;&gt;-->
<!--              <div class="empty-state py-5">-->
<!--                <img class="img-fluid" src="@/assets/images/pages/shift-icon.png" alt="">-->
<!--                <p class="txt-grey mt-1">No Shifts</p>-->
<!--              </div>-->

<!--          </div>-->

<!--      </b-card-body>-->
<!--    </b-card>-->
<!--  </div>-->
<!--</template>-->

<template>
  <div>
    <b-card no-body>

      <b-card-header class="border-bottom pb-1">
        <div class="d-flex flex-row">

          <b-avatar
              rounded
              variant="light-primary"
          >
            <feather-icon
                class="text-dark font-weight-bolder"
                icon="CalendarIcon"
                size="18"
            />
          </b-avatar>
          <div class="ml-1" style="padding-top:8px">
            <span style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 20px; line-height: 25px; color: #171822;">
              Shifts
            </span>
          </div>

        </div>
        <div class="ml-auto">
          <b-button
              class="btn-icon bg-white shadow-sm font-weight-bold"
              variant="white"
          >
            <feather-icon
                v-b-tooltip.hover.top="'Download'"
                icon="DownloadIcon"
                size="20"
                class="text-primary"
            />
          </b-button>
        </div>
      </b-card-header>
      <b-card-body class=" pl-1 px-0 mx-0">
        <b-row v-if="noDataTable !== 0">
          <b-col cols="12">
            <b-overlay
                :show="tableLoading"
                rounded="sm"
            >

              <b-table
                  :current-page="currentPage"
                  :per-page="pagination.perPage"
                  :fields="fields"
                  :items="getShifts"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"

                  hover
                  ref="table"

                  class="mobile_table_css table_shifts"

                  responsive
              >


                <template #cell(subject)="data">
                  <!--                  <b-badge v-if="data.item.status ==='Requested'" class="ml-1" style="padding:8px" variant="warning" >-->
                  <!--                    <span class=""> {{data.item.status}} </span>-->
                  <!--                  </b-badge>-->
                  <!--                  <b-badge v-if="data.item.status ==='Approved'" class="ml-1" style="padding:8px" variant="success" >-->
                  <!--                    <span class=""> {{data.item.status}} </span>-->
                  <!--                  </b-badge>-->
                  <!--                  <b-badge v-if="data.item.status ==='Rejected'" class="ml-1" style="padding:8px" variant="danger" >-->
                  <!--                    <span class=""> {{data.item.status}} </span>-->
                  <!--                  </b-badge>-->
                  <!--                  <b-badge v-if="data.item.status ==='Cancelled'" class="ml-1" style="padding:8px" variant="info" >-->
                  <!--                    <span class=""> {{data.item.status}} </span>-->
                  <!--                  </b-badge>-->
                  <div class="pl-1 ">
                    <h5 style="font-family: Mulish; font-style: normal; font-weight: bold; font-size: 16px; line-height: 20px; color: #171822;">
                      {{data.item.subject}}
                      <b-badge v-if="data.item.is_covid"
                               :variant="'light-danger'"
                               class="text-capitalize"
                      >
                        COVID-19
                      </b-badge>
                    </h5>
                    <span class="mr-1" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 21px; color: #171822;">
                      <feather-icon class="mr-1" icon="CalendarIcon"/>
                      {{ data.item.date }}
                    </span>

                    <span class="mr-1" style="font-family: Mulish; font-style: normal; font-weight: normal; font-size: 14px; line-height: 21px; color: #171822;">
                      <feather-icon class="mr-1" icon="ClockIcon"/>
                      {{ data.item.time }}
                    </span>
                    <!--                                  <span class="mr-1">-->
                    <!--                                        <feather-icon icon="DatabaseIcon"/>-->
                    <!--                                        £750,000-->
                    <!--                            </span>-->
                  </div>


                </template>
                <template #cell(action)="data">
                  <b-badge
                      v-if="data.item.status === 'Active'"
                      :variant="'light-primary'"
                      class="text-capitalize  float-right"
                  >
                    {{data.item.status}}
                  </b-badge>

                  <b-badge
                      v-if="data.item.status === 'Upcoming'"
                      style="background-color: rgba(239, 161, 44, 0.1); color: #EFA12C;"
                      class="text-capitalize  float-right"
                  >
                    {{data.item.status}}
                  </b-badge>

                  <b-badge
                      v-if="data.item.status === 'Past'"
                      style="background-color: rgba(178, 178, 178, 0.1); color: rgba(23, 24, 34, 0.6);;"
                      class="text-capitalize  float-right"
                  >
                    {{data.item.status}}
                  </b-badge>
                  <!--                  <b-button-->
                  <!--                      size="23"-->
                  <!--                      variant="outline-white"-->
                  <!--                      class="btn-icon bg-white text-primary m-1 "-->
                  <!--                      @click="acceptLeave(data.item.id)"-->
                  <!--                  >-->
                  <!--                    <feather-icon icon="CheckSquareIcon" size="18" />-->
                  <!--                  </b-button>-->



                  <!--                  <b-button-->
                  <!--                      size="23"-->
                  <!--                      variant="outline-white"-->
                  <!--                      class="btn-icon bg-white text-danger m-1"-->
                  <!--                      @click="rejectLeave(data.item.id)"-->
                  <!--                  >-->
                  <!--                    <feather-icon icon="XSquareIcon" size="18" variant="outline-danger"/>-->
                  <!--                  </b-button>-->


                </template>


              </b-table>
            </b-overlay>
          </b-col>
          <b-col cols="12" v-if="noDataTable === 0" class="text-center">
            <span >No data for preview</span>
          </b-col>
          <!-- Pagination -->

          <b-col class="d-flex align-items-center pl-1 justify-content-center justify-content-sm-start" cols="12" sm="6">
            <span class="text-muted">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
          </b-col>

          <!-- Pagination -->

          <b-col class="d-flex align-items-center pr-4 justify-content-center justify-content-sm-end" cols="12" sm="6">

            <b-pagination
                v-model="currentPage"
                :per-page="pagination.perPage"
                :total-rows="pagination.totalRows"
                class="mb-0 mt-1 mt-sm-0 "
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item">

              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>

            </b-pagination>

          </b-col>
        </b-row>

        <!--        <vue-perfect-scrollbar v-if="true"-->
        <!--            class="todo-task-list-wrapper list-group scroll-area min-vh-50 "-->
        <!--            style="max-height: 315px"-->
        <!--        >-->

        <!--          <div v-for="index in [1,2,3,4,5,6,7,8,9,10]" class="d-flex flex-row my-1 border-bottom">-->
        <!--            <div class="pl-1 ">-->
        <!--              <h5 class="font-weight-700">Tottenham Hale Medical Practice-->
        <!--                <b-badge-->
        <!--                    :variant="'light-danger'"-->
        <!--                    class="text-capitalize  mr-2"-->
        <!--                >-->
        <!--                  COVID-19-->
        <!--                </b-badge>-->
        <!--              </h5>-->
        <!--              <span class="mr-1">-->
        <!--                    <feather-icon icon="CalendarIcon"/>-->
        <!--                    Apr 15, 2021 - Jun 15, 2021-->
        <!--        </span>-->
        <!--              <span class="mr-1">-->
        <!--                    <feather-icon icon="DatabaseIcon"/>-->
        <!--                    £750,000-->
        <!--        </span>-->
        <!--            </div>-->
        <!--            <div class="ml-auto ">-->
        <!--              <b-badge-->
        <!--                  :variant="'light-success'"-->
        <!--                  class="text-capitalize mt-1 mr-1"-->
        <!--              >-->
        <!--                Active-->
        <!--              </b-badge>-->
        <!--              <ProfileShiftsDropDownMenu/>-->

        <!--            </div>-->
        <!--          </div>-->

        <!--        </vue-perfect-scrollbar>-->

        <div v-else class="d-flex flex-column align-items-center justify-content-center h-100">

          <div class="empty-state py-1">
            <img class="img-fluid" src="@/assets/images/pages/shift-icon.png" alt="" width="100">
            <p class="txt-grey mt-1">No Shifts</p>
          </div>

        </div>

      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {BAvatar, BBadge, BCard, BCardBody, BCardHeader, BCol, BImg, BMedia, BRow, BPagination,
    BOverlay,
    BTable} from 'bootstrap-vue'
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ProfileShiftsDropDownMenu from "@/views/profile/includes/dropdowns/ProfileShiftsDropDownMenu";
import StaffBankAPI from "@/apis/modules/staffBank";
import MomentMixin from '@/mixins/MomentMixin'

export default {
  components: {
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BBadge,
    BPagination,
    BOverlay,
    BTable,
    VuePerfectScrollbar,
    ProfileShiftsDropDownMenu
  },
  props:{
    userData:{
      type: Object,
      required: true
    }
  },
  mixins: [MomentMixin],
  data(){
    return {
      fields: [
        {
          key: 'subject',
          label:'',
        },

        {
          key:'action',
          label:''
        }
      ],
      items: [],
      leaves: [],
      tableLoading: false,
      noDataTable:0,
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc'
    }
  },
   methods:{
    async getShifts(){
      try {
        this.tableLoading = true
        const Response = await StaffBankAPI.getStaffShifts(this.$route.params.id, this.currentPage, 5)
        this.noDataTable = Response.data.data.length
        let dataArray = Response.data.data.map((x) => ({
          id: x.id,
          date: this.momentFormat(x.start,'ddd, MMM DD'),
          subject:x.service ===  null? '':x.service.name,
          //rate: x.rate,
          time: `${this.momentFormat(x.start,'HH:mm')}` + ' ' + `${this.momentFormat(x.end,'HH:mm')}`,
          is_covid:x.is_covid_shift,
          status:this.getStatus(this.momentFormat(x.start,'MM/DD/YYYY'))
        }))
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage= paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false

        return dataArray

      }catch (error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },

  },
  mounted(){
    this.getShifts()
  }
}
</script>
<style  >
.table_shifts table thead{
  display: none !important;
}
</style>